// @import url('https://fonts.googleapis.com/css?family=Contrail+One');

:root {

}
body {
  margin:0;
  // background-color: var(--color-background);
  overscroll-behavior: none;
  overflow: hidden;
}
* {
    -user-select: none;
    user-select: none;
    -webkit-user-select: none; /* Chrome/Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+ */
    outline: none;
    box-shadow: none;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    touch-action: none;
    // -webkit-user-select: text;
    -webkit-text-size-adjust: none;
}
html {
    -webkit-text-size-adjust: none
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
    select,
    textarea,
    input {
      font-size: 16px;
    }
  }

/* ==========================================================================
Including the external CSS files
========================================================================== */
/*@import "svg-vars.less?v=2";*/

/* ==========================================================================
  LESS VARIABLES
  ========================================================================== */
//Global colors
@primary: #1d3544;
@secondary: #f78d1e;

//Text colors
@TWhite: #fff;
@TDark: #015a8e;

header {
  font-size: 180%;
  padding-top: 1px;

  h1 {
    color: @primary;
  }
}

#main-wrapper{
  position:relative;
  margin: 0 auto;
  width:100%;
  height:100%;
}

#canvas{
  position:fixed;
  top:0;
  left:0;
  bottom: 0;
  right: 0;
//   width:100%;
//   height:100%;
  overflow: hidden;
  z-index: 4;
  box-sizing: border-box;
}

#score-label{
    position: absolute;
    width:100%;
    text-align:center;
    z-index: 4;
    color: #1d3544;
    font-size: 28px;
    pointer-events: none;
    top: 20px;
    box-sizing: border-box;
}

#lives-container{
position: absolute;
  left: 10px;
  top: 10px;
  pointer-events: none;
  z-index: 40;
  display: flex;
  gap: 3px;
  justify-content: center;
  align-items: center;
}

@keyframes flanus {
        // 100% { background-position: 0 -388px; }
}

.plane {
    pointer-events: none;
	//background-image: url("/assets/img/plane-sprite.png");
    background-repeat: no-repeat;
    background-size: 100%;
    height: 100px;
    width: 100px;
    position: absolute;
    display: inline-block;
    z-index:2;
    background-size: contain;
}

.life {
	  //background-image: url("/assets/img/full-balloon.svg");
	background-size: contain;
    background-position: center;
    height: 20px;
    pointer-events: none;
    width: 20px;
    position: relative;
    display: inline-block;
    background-repeat: no-repeat;
    z-index:2;


}
@keyframes cloud {
  100%{opacity: 1;}
}

.balloon{
    pointer-events: all;
    position:absolute;
    // width:50px;
    height:100px;
    width:100px;
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
    z-index:10;
    background-size: contain;


    &.debug{
        border-style: solid;
    }

    &.explosion {
        position:absolute;
        // width:50px;
        // height:100px;
        // width: 71px;
        // width:20%;
        background-repeat: no-repeat;
        // background-size: 100%;
        background-position: unset;
        background-size: cover;
        animation-fill-mode: forwards;
        pointer-events: none;
        z-index:10;
        cursor: none;
            // transform: scale(2)

    }




}

#splash {
  position: absolute;
  width: 100%;
  height: 100%;
}

.splashcontent
{
  position: absolute;
  width: 100%;
  height: 100%;

  background-position: 50% 50%;
  background-size: 100%;
  z-index: 100;

  // background-size: contain;

  background-repeat: no-repeat;
}



.cloud{
    pointer-events: none;
    position:absolute;
    width:100px;
    height:100px;
    background-repeat: no-repeat;
    background-size: contain;
    z-index:1;
}

@keyframes flylittlebird {
    to {
        // background-position: -324.99px 0;
        margin-bottom: 10px;
    }
}

.bird {
  	// background-image: url("/assets/img/bird.svg");
	height: 65px;
    width: 64px;
    background-repeat: no-repeat;
    background-size: 324.99px;
    background-size: contain;
	z-index:2;
    position: absolute;
    pointer-events: none;
}

&.rtl {
	  transform: rotateY(180deg);
}
.overlay {
    display: none;
    position: fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;

    z-index: 3000;
    .overlayContent {
        display: flex;
        height: 100%;
        width: 100%;
        align-items: center;
        justify-content: center;
        font-size: 1.2rem;
        text-align: center;
    }
}
#debugSpeed {


}
#debugInfo {
    position: fixed;
    bottom:0;
    left:0;
    pointer-events: none;
    z-index: 5000;
    display: none;
    border: 1px solid black;
    width: 100%;
    font-weight: 100;
    box-sizing: border-box;

}