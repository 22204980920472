body {
  overscroll-behavior: none;
  margin: 0;
  overflow: hidden;
}

* {
  -user-select: none;
  -webkit-user-select: none;
  user-select: none;
  box-shadow: none;
  -webkit-tap-highlight-color: #0000;
  touch-action: none;
  -webkit-text-size-adjust: none;
  outline: none;
}

html {
  -webkit-text-size-adjust: none;
}

@media screen and (-webkit-device-pixel-ratio >= 0) {
  select, textarea, input {
    font-size: 16px;
  }
}

header {
  padding-top: 1px;
  font-size: 180%;
}

header h1 {
  color: #1d3544;
}

#main-wrapper {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  position: relative;
}

#canvas {
  z-index: 4;
  box-sizing: border-box;
  position: fixed;
  inset: 0;
  overflow: hidden;
}

#score-label {
  text-align: center;
  z-index: 4;
  color: #1d3544;
  pointer-events: none;
  box-sizing: border-box;
  width: 100%;
  font-size: 28px;
  position: absolute;
  top: 20px;
}

#lives-container {
  pointer-events: none;
  z-index: 40;
  justify-content: center;
  align-items: center;
  gap: 3px;
  display: flex;
  position: absolute;
  top: 10px;
  left: 10px;
}

.plane {
  pointer-events: none;
  z-index: 2;
  background-repeat: no-repeat;
  background-size: contain;
  width: 100px;
  height: 100px;
  display: inline-block;
  position: absolute;
}

.life {
  pointer-events: none;
  z-index: 2;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 20px;
  height: 20px;
  display: inline-block;
  position: relative;
}

@keyframes cloud {
  100% {
    opacity: 1;
  }
}

.balloon {
  pointer-events: all;
  z-index: 10;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 100px;
  height: 100px;
  position: absolute;
}

.balloon.debug {
  border-style: solid;
}

.balloon.explosion {
  background-repeat: no-repeat;
  background-position: unset;
  pointer-events: none;
  z-index: 10;
  cursor: none;
  background-size: cover;
  animation-fill-mode: forwards;
  position: absolute;
}

#splash {
  width: 100%;
  height: 100%;
  position: absolute;
}

.splashcontent {
  z-index: 100;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100%;
  width: 100%;
  height: 100%;
  position: absolute;
}

.cloud {
  pointer-events: none;
  z-index: 1;
  background-repeat: no-repeat;
  background-size: contain;
  width: 100px;
  height: 100px;
  position: absolute;
}

@keyframes flylittlebird {
  to {
    margin-bottom: 10px;
  }
}

.bird {
  z-index: 2;
  pointer-events: none;
  background-repeat: no-repeat;
  background-size: contain;
  width: 64px;
  height: 65px;
  position: absolute;
}

.rtl {
  transform: rotateY(180deg);
}

.overlay {
  z-index: 3000;
  display: none;
  position: fixed;
  inset: 0;
}

.overlay .overlayContent {
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: 1.2rem;
  display: flex;
}

#debugInfo {
  pointer-events: none;
  z-index: 5000;
  box-sizing: border-box;
  border: 1px solid #000;
  width: 100%;
  font-weight: 100;
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
}

/*# sourceMappingURL=index.ea98bdf8.css.map */
